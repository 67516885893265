<template>
	<FormLista tituloBtn="Create" @salvarLista="enviarLista($event)" />
</template>

<script>
	import firebase from 'firebase';
	import FormLista from '@/components/elementos/FormLista.vue';
	export default {
		name: 'CadastroLista',
		components: {
			FormLista,
		},
		methods: {
			enviarLista(data) {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.database()
					.ref('users')
					.child(firebase.auth().currentUser.uid)
					.push(data)
					.then(() => {
						this.$router.push('/');
						this.$store.commit('user/SET_ATUALIZAR_LISTA', true);
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: '\\o/',
							mensagem: 'Your list has been created',
						});
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
	};
</script>
